import { 
    CLOSE_BTN_TEMPLATE, 
    NAV_TEMPLATE,
    SELECTED_TOUR_TEMPLATE,
    FOOTER_LOGO,
} from 'booking-widget/v2/templates/shared';

export const EMAIL_THIS_PAGE = `
<form name="email-form" class="bw-form bw-form--email" action="" autocomplete="on">
    <input type="text" name="emails" value="" placeholder="Email addresses (separated with a comma)" required>
    <textarea name="message" width="100%" rows="5" placeholder="Optional message"></textarea>
    <div>
        <input type="submit" class="bw-button bw-form-submit bw-form--email__button" data-original="Continue to Payment" value="Share My Trip">
    </div>
    <div class="bw-form--email-message"></div>
</form>
`;

export const CONFIRMED_TOUR_TEMPLATE = `
<div class="bw bw-confirmed">
    <div class="bw-header--trigger"></div>
    <div class="bw-header">
        ${NAV_TEMPLATE}
        ${CLOSE_BTN_TEMPLATE}
    </div>
    <div class="bw-title">
        <div class="bw-confirmed__title">
            <h1 class="bw-text-center">
                <span class="bw-desktop-only">Booking Confirmed.  Welcome Aboard!</span>
            </h1>
            <p class="bw-text-center">A confirmation email will be sent to <span class="bw-primary">[email]</span></p>
        </div>
    </div>
    <div class="bw-body">
        ${SELECTED_TOUR_TEMPLATE}
        <div class="bw-mt--60">
            <h2>Get ready for your trip!</h2>
            <p class="bw-fs--md-2">Please watch this short briefing video prior to your tour.</p>
            <div class="bw-video-container bw-mb--40">
                <iframe src="[tour_video_url]" title="Short briefing video" frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <div class="bw-mb--40">
            <h2>Just a few things to help you be better prepared!</h2>
            [tour_res_complete_partial]
        </div>
        <hr />
        <div class="bw-confirmed__share">
            <h2>Share this trip info with your friends</h2>
            <div class="bw-form-wrap">
                ${EMAIL_THIS_PAGE}
            </div>
        </div>
        <hr />
        <div class="bw-confirmed__links">
            <a href="[link_directions]" target="_blank">Departure, check in and driving directions</a>
            <a href="[url_root_public]faq" target="_blank">Frequently Asked Questions</a>
            <a href="[url_root]downloads/ics/[res_number]">Add to calendar</a>
        </div>
        <hr />
    </div>
    <div class="bw-footer">
        <h2>We appreciate your business.  Mahalo.</h2>
        ${FOOTER_LOGO}
    </div>
</div>

<div class="bw-food-preference-popup-container" style="display: none"></div>
`;


export const CONFIRMED_GC_TEMPLATE = `
<div class="bw bw-confirmed">
    <div class="bw-header--trigger"></div>
    <div class="bw-header">
        ${NAV_TEMPLATE}
        ${CLOSE_BTN_TEMPLATE}
    </div>
    <div class="bw-confirmed__title">
        <h1 class="bw-text-center">
            <span class="bw-desktop-only">Gift Certificate Purchased</span>
        </h1>
        <p class="bw-text-center">A confirmation email will be sent to <span class="bw-primary">[email]</span></p>
    </div>
    <div class="bw-body">
        ${SELECTED_TOUR_TEMPLATE}
        <div class="bw-mt--60">
            <h2 class="mb-2">Gift Code: [gift_code]</h2>
            <p class="bw-fs--md-2">
            Expires: [expires]<br>
            Value: [gc_value]<br>
            <span class="bw-fs--sm-md">*The Gift Code is used to redeem this gift certificate when making a reservation online or by phone.</span>
            </p>
        </div>
        <hr class="bw-mt--60" />
    </div>
    <div class="bw-footer">
        <h2>We appreciate your business.  Mahalo.</h2>
        ${FOOTER_LOGO}
    </div>
</div>

<div class="bw-food-preference-popup-container" style="display: none"></div>
`;