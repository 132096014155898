import { 
    CLOSE_BTN_TEMPLATE, 
    NAV_TEMPLATE,
    SELECTED_TOUR_TEMPLATE,
    CONTACT_INFO_FIELDS,
    FOOTER_LOGO,
    ERROR_MESSAGE,
    LINK_EMAIL,
    LINK_PHONE,
    MOBILE_SUBMIT_BAR
} from 'booking-widget/v2/templates/shared';

export const TC_AGREE_CHECKBOXES = `
<label class="bw-highlight--checkbox bw-mb--30" style="display: inline-block;">
    <input type="checkbox" name="tc_agree" value="1" class="bw-mr--05em" oninvalid="this.setCustomValidity(\'This is required to proceed.\')" oninput="this.setCustomValidity(\'\')" required />I agree to the terms and conditions listed above.
</label>
`;
export const PAYMENT_TEMPLATE = `
<div class="bw bw-payment">
    <div class="bw-header--trigger"></div>
    <div class="bw-header">
        ${NAV_TEMPLATE}
        ${CLOSE_BTN_TEMPLATE}
    </div>
    <div class="bw-title bw-desktop-only-block bw-mb--md-37">
        <h1 class="bw-text-center bw-mb--0">Secure Payment <span class="bw-gc-hide">&amp; Terms</span></h1>
    </div>
    <div class="bw-body">
        ${SELECTED_TOUR_TEMPLATE}
        <div class="bw-form-wrap">
            ${ERROR_MESSAGE}
            <form class="bw-form" action="" autocomplete="on">
                <h2 class="bw-mt--10"><span class="bw-gc-hide">Primary</span> Contact Info</h2>
                ${CONTACT_INFO_FIELDS}
                <div class="bw-form--payment">
                    <h2 class="bw-mt--8 bw-mt--md-30">Secure Payment</h2>
                    <div class="bw-row bw-red payment-error-message" style="display:none"></div>
                    <div class="bw-row">
                        <label for="cc_number" class="bw-sr">Card Number</label>
                        <input placeholder="*XXXX XXXX XXXX XXXX" type="text" id="cc_number" name="cc_number" autocomplete="cc-number" value="" required>
                    </div>
                    <div class="bw-row--flex-row">
                        <div class="bw-flex-basis-66">
                            <label for="cc_exp" class="bw-sr">Expiration (MM/YY)</label>
                            <input type="text" id="cc_exp" name="cc_exp" placeholder="*Expiration (MM/YY)" data-target="cc-exp" required>
                        </div>
                        <div class="bw-flex-basis-33">
                            <label for="cvv_code" class="bw-sr">CVV</label>
                            <input type="text" id="cvv_code" name="cc_cvv" placeholder="*CVV" data-target="cc-cvv" maxlength="4" required>
                        </div>
                    </div>
                    <div class="bw-row--flex-row">
                        <div class="bw-flex-basis-66">
                            <label class="bw-sr" for="cc_name">*Name on Card</label>
                            <input type="text" id="cc_name" name="cc_name" placeholder="*Name on Card" data-target="cc-name" required>
                        </div>
                        <div class="bw-flex-basis-33">
                            <label for="cc_zip" class="bw-sr">Zip Code</label>
                            <input type="text" id="cc_zip" name="cc_zip" placeholder="*Zip code" data-target="cc-zip" required>
                        </div>
                    </div>
                </div>
    
                <div class="bw-gc-hide">
                    <a class="bw-terms-link" href="#">By booking, I agree to Capt Andy's <span style="text-decoration:underline;">terms & conditions</span> <span class="bw-icon--chevron-up"></span><span class="bw-icon--chevron-down"></a>
                </div>
                
                <div class="bw-terms-conditions bw-gc-hide" style="display:none;">
                    <h1 class="bw-text-upper">Terms & Conditions</h1>
                </div>

                <div class="bw-payment-secure-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25.826" viewBox="0 0 18 25.826"><g id="XMLID_504_" transform="translate(-50)"><path id="XMLID_505_" d="M51.174,25.826H66.826A1.174,1.174,0,0,0,68,24.652v-13.3a1.174,1.174,0,0,0-1.174-1.174H65.652V6.652a6.652,6.652,0,1,0-13.3,0v3.522H51.174A1.174,1.174,0,0,0,50,11.348v13.3A1.174,1.174,0,0,0,51.174,25.826Zm11.151-8.659L59,20.493a1.174,1.174,0,0,1-1.66,0L55.675,18.83a1.174,1.174,0,0,1,1.66-1.66l.833.833,2.5-2.5a1.174,1.174,0,1,1,1.66,1.66ZM54.7,6.652a4.3,4.3,0,1,1,8.609,0v3.522H54.7Z" transform="translate(0)" fill="green"/></g></svg> Payments are secure.
                </div>

                <div class="bw-best-price bw-my--md-20">
                    Best Price Guarantee: You're booking direct with Capt Andy's. We guarantee you won't find our tours for less anywhere else. If you do, we'll refund you the difference. 
                </div>

                <input type="hidden" id="cardholder_name">
                <input type="hidden" id="exp_month">
                <input type="hidden" id="exp_year">
                <input type="submit" class="bw-button bw-form-submit bw-desktop-only" data-original="Continue to Payment" value="Complete Booking">
            </form>
        </div>
    </div>
    <div class="bw-footer bw-desktop-only-flex">
        ${FOOTER_LOGO}
    </div>
    ${MOBILE_SUBMIT_BAR}
</div>

<div class="bw-food-preference-popup-container" style="display: none"></div>
`;

export const PAYMENT_ERROR_RES_SUCCESS = `Your payment has gone through, but your reservation is still pending.  Please email ${LINK_EMAIL} or call ${LINK_PHONE} with your reservation number: `;


export const PAYMENT_ERROR_GENERIC = `Your payment could not be processed.  Please ensure all information is correct and try again.`;