import { 
    local_to_tz_display,
    convertISOtoDate,
    to_system_date
} from 'utils/date';

import { hide_loading } from 'booking-widget/v2/common/util';
import { esc_html } from 'utils/strings';

import { init as init_pikaday, 
        destroy as destroy_pikaday,
        get_datestring as datepicker_datestring
} from 'booking-widget/v2/widgets/datepicker';

import { STEPS, hide_fullscreen_modal } from 'booking-widget/v2/bw';
import { PICKERS_TEMPLATE } from 'booking-widget/v2/templates/search';

import { hide as hide_popup } from 'ui/popup';

import { 
    init as init_guestpicker, 
    get_guest_counts, 
    destroy_guestpicker,
    set_guest_counts,
    set_guestpicker_display
}
from 'booking-widget/v2/widgets/guestpicker';

let widget_el_data_id = '';

export const load = (options, bw_events) => {
    let opts = $.extend(true, {
        search_header: '',
        start_time: false,
        adult: false,
        child: false,
        toddler: false,
        tour_id: 0,
        search_tag: false,
        is_charter: false,
        vessel_type: false,
        theme: '',
    }, (options || {}));


    const bw_el = hide_fullscreen_modal(opts.context_el);
    // just in case, hide loading
    hide_loading(bw_el, true);
    
    if (!opts.search_selector) {
        return;
    }
    let el = $(opts.search_selector);
    if (el.length == 0) {
        return;
    }
    el.empty();

    if (opts.theme) {
        el.addClass(opts.theme);
    }

    let widget_el = el;
    let body_el = $('body');
    let is_tour_specific = (opts.tour_id && opts.tour_id > 0);
    let is_compact = (opts.theme == 'bw-compact');
    let bwgp_id = opts.identifier + '-bwgp-widget';
    widget_el_data_id = opts.identifier;
    widget_el.attr('data-bw-id', opts.identifier);

    if (opts.search_header !== false) {
        if (opts.search_tag !== false) {
            opts.search_header = 'Book ' + opts.search_tag.name + ' Tours';
        }
        else if (opts.is_charter) {
            opts.search_header = 'Book your Private Charter Online';
        }
        else {
            opts.search_header = 'Book Your Adventure';
        }
    }

    const search_header = opts.search_header !== false ?  '<h3><strong>' + esc_html(opts.search_header) + '</h3></strong>' : '';
    const pickers_tpl = PICKERS_TEMPLATE
        .replace(/\[search_header\]/g, search_header)
        .replace(/\[bwgp_id\]/g, bwgp_id)
        .replace(/\[widget_el_data_id\]/g, `[data-bw-id='${widget_el_data_id}']`)
        .replace(/\[passengers_label\]/g, is_compact ? 'Guests' : 'Passengers')
        .replace(/\[dates_label\]/g, is_compact ? 'Dates' : '<span class="bw-desktop-only">Tour </span> Date')
        
    ;
    widget_el.append(pickers_tpl);

    if (opts.is_charter) {
        widget_el.attr('data-charters', 1);
        if (!is_compact) {
            // no vessels when compact
            $('.bw-search', widget_el).addClass('bw-charters');
            if (opts.vessel_type) {
                const vessel_types = opts.vessel_type.split(',');
                $('input[name="vessel_type"]', widget_el).each(function() {
                    const is_checked = vessel_types.includes($(this).val());
                    $(this).prop('checked', is_checked);
                });
            }
        }
    }
    let default_date = new Date();
    default_date = to_system_date(default_date);
    let start_time              = opts.start_time ? opts.start_time : default_date;
    let datepicker_el           = $('.bw-pickers__picker--datepicker', widget_el);
    let datepicker_display_el   = $('.bw-pickers__datepicker-display', widget_el);
    let guestpicker_control_el  = $('.bw-pickers__picker--guestpicker', widget_el);
    let search_button_el        = $('.bw-pickers__btn-submit', widget_el);

    init_guestpicker({
        popup_id: bwgp_id,
        display_el: guestpicker_control_el,
        adult: opts.adult,
        child: opts.child,
        toddler: opts.toddler,
        is_tour_specific: is_tour_specific,
        is_raft_only: opts.is_raft_only,
        is_compact: is_compact,
        on_hidden: function(popup_el, guest_counts) {
            const other_popups = $('.bw-popup.bwgp-widget').not(popup_el);
            other_popups.each(function() {
                set_guest_counts($(this), guest_counts);
            })

            const other_displays = $('.bw-pickers__picker--guestpicker').not(guestpicker_control_el);
            other_displays.each(function() {
                let is_compact = other_displays.closest('.bw-search').parent().hasClass('bw-compact');
                set_guestpicker_display(guest_counts, $(this), is_compact)
            })
        }
    });

    const pikaday_options = {
        datepicker_el: datepicker_el, 
        utc_offset: opts.utc_offset,
        start_time: opts.start_time,
        margin_top: (is_compact ? '20px' : ''),
        beforeOnOpen: function() {
            body_el.addClass('bw-bwdp-showing');
            if (is_compact) {
                body_el.addClass('bw-bwdp-showing--compact');
            }
        },
        onClose: function() {
            body_el.removeClass('bw-bwdp-showing bw-bwdp-showing--compact');

        },
        onInit: function(pikaday, opts) {
            if (start_time) {
                set_datepicker_display(datepicker_display_el, start_time);
            }
            else {
                set_datepicker_display(datepicker_display_el, false);
            }
        },
        onSelect: function(date) {
            set_datepicker_display(datepicker_display_el, to_system_date(date));
            // no feasible way to get other pikaday objects
            // set other dp displays
            $('.bw-pickers__datepicker-display').not(datepicker_display_el).each(function() {
                set_datepicker_display($(this), to_system_date(date));
            })
        }
    };

    let pikaday = init_pikaday(pikaday_options);
    if (!pikaday || !datepicker_el.hasClass('pika-initialized')) {
        $(document)
            .off('bw-pikaday-loaded')
            .on('bw-pikaday-loaded', function() {
                pikaday = init_pikaday(pikaday_options);
            });
    }

    let gp_container_el = $('.bw-pickers__picker-col--gp', el);

    search_button_el
        .off('click')
        .on('click', function(e) {
            e.preventDefault();

            if (gp_container_el.hasClass('ts-popup--showing')) {
                hide_popup($('.ts-popup', gp_container_el));
            }
            
            let date = datepicker_datestring(pikaday);
            if (date == '') {
                let default_date = new Date();
                default_date.setDate(default_date.getDate() + 1);
                date = to_system_date(default_date);
            }
            let selections = $.extend({
                start_time: date,
                tour_id: opts.tour_id,
            }, get_guest_counts(gp_container_el), get_vessel_type(opts, widget_el));
            selections.is_charter = opts.is_charter ? opts.is_charter : 0;

            destroy_pikaday(pikaday);
            destroy_guestpicker();
            bw_events.on_submit(selections);
    });
    
    $(window).on('resize', function() {
        toggle_mobile_view(el, bwgp_id, is_compact);
    });

    toggle_mobile_view(el, bwgp_id, is_compact);

    bw_events.on_loaded();
}

// convert search params to url params
export const to_url_params = (search_params) => {
    const ret = {
        step: STEPS.search.name,
        start_time: search_params.start_time ? search_params.start_time : '',
        adult: search_params.adult,
        child: search_params.child,
        toddler: search_params.toddler,
        tour_id: search_params.tour_id ? search_params.tour_id : 0,
    }

    if (search_params.vessel_type) {
        ret.vessel_type = search_params.vessel_type;
    }

    return ret;


    function bw_guestpicker_id() {
        return opts.identifier + '-bwgp-widget'
    }
}

function set_datepicker_display(datepicker_display_el, start_datestring) {
    if (!datepicker_display_el) {
        return;
    }
    let str = 'Select date';
    if (start_datestring !== false) {
        let date = convertISOtoDate(start_datestring + ' 00:00:00');
        str = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
        datepicker_display_el.addClass('bw-picked');
    }
    datepicker_display_el.html(str);
}

function toggle_mobile_view(search_el, bwgp_id, is_compact) {
    let gp_container_el = $('.bw-pickers__picker-col--gp', search_el);
    if (gp_container_el.length == 0) {
        return;
    }

    if ($(window).outerWidth() < 768) {
        // attach
        if (!gp_container_el.hasClass('bwgp-attached') && !is_compact) {
            let gp_el = $('#' + bwgp_id + ' .bwgp-widget__container');
            $('label', gp_container_el).after(gp_el);
            gp_container_el.addClass('bwgp-attached');
        }
    }
    else if (gp_container_el.hasClass('bwgp-attached')) {
        // detach
        let gp_el = $('.bwgp-widget__container', gp_container_el);
        $('#' + bwgp_id).append(gp_el);
        gp_container_el.removeClass('bwgp-attached');
    }
    else if (gp_container_el.hasClass('ts-popup--showing')) {
        hide_popup($('.ts-popup', gp_container_el));
    }
}

function get_vessel_type(opts, widget_el) {
    if (!opts.is_charter) {
        return {};
    }
    let vals = [];
    $('input[name="vessel_type"]:checked', widget_el).each(function() {
        vals.push($(this).val());
    });
    return { vessel_type: vals.join(',') };
}